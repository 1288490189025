import React, { memo } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import { checkExpried, loaded } from './../utils/helpers';
import Lazyload from 'react-lazyload';
import Seo from '../components/common/Seo';
import { useSearch } from '../hooks';
import { getSrc } from 'gatsby-plugin-image';

const GraphQLError = loadable(() => import('../components/error'));
const Layout = loadable(() => import('../layout'));
const Banner = loadable(() => import('../components/home_page/Banner'));
// const BannerMobile = loadable(() => import('../components/home_page/BannerMobile'));

const HeaderSubNavigation = loadable(() => import('../components/header_sub_navigation'));
const LinkListGroup = loadable(() => import('../components/home_page/LinkListGroup'));
const PromotionSliderGroup = loadable(() => import('../components/home_page/PromotionSliderGroup'));
const SliderProductNew = loadable(() => import('../components/home_page/SliderProductNew'));
const SliderBlogPost = loadable(() => import('../components/home_page/SliderBlogPost'));
const TrandingBrands = loadable(() => import('../components/home_page/TrendingBrands'));
const ShopByCategory = loadable(() => import('../components/home_page/ShopByCategory'));
const FooterText = loadable(() => import('../components/home_page/FooterText'));

const IndexPage = (props) => {
  const { data, errors, location } = props;
  const isMobile = loaded && window.innerWidth < 768;
  const isPC = loaded && window.innerWidth >= 768;
  const { searching, query } = useSearch();

  if (errors) {
    return <GraphQLError errors={errors} />;
  }
  const { homePage } = data;
  const _data = homePage.data;
  const banner_group = _data.banner_group.filter(
    ({ start_date, end_date }) => !checkExpried(start_date, end_date)
  );

  const promotion_slider_group = _data.promotion_slider_group.filter(
    ({ start_date, end_date }) => !checkExpried(start_date, end_date)
  );

  return (
    <Layout
      location={location}
      className={'home-page'}
      showHeaderSubNavgation={true}
      hideChildren={false}
    >
      {(!searching || !query) && (
        <>
          {/*main__banner*/}
          {/* <BannerMobile data={banner_group} /> */}
          <Banner data={banner_group} />
          {/*end main__banner*/}

          {/* main__services */}
          <HeaderSubNavigation type="main__services" />
          {/* end main__services */}

          {/*main__categories*/}
          {isPC && <LinkListGroup data={_data.link_list_group} />}
          {/*end main__categories */}

          {/*main__product-new*/}
          {/* <Lazyload throttle={500} height={300} scroll={true}> */}
          <SliderProductNew
            title={_data.product_group_1_title}
            url={_data.product_group_1_url}
            data={_data.product_group_1}
            button_text={_data.product_group_1_button_text}
            button_link={_data.product_group_1_button_link}
          />
          {/* </Lazyload> */}
          {/*end main__product-new*/}

          <Lazyload throttle={500} height={1000} scroll={true}></Lazyload>

          {/*main__categories*/}
          {isMobile && <LinkListGroup data={_data.link_list_group} skip={0} limit={3} />}
          {/*end main__categories */}

          {/*main__featured*/}
          <Lazyload throttle={500} height={300} scroll={true}>
            <PromotionSliderGroup data={promotion_slider_group} />
          </Lazyload>
          {/*end main__featured*/}

          {/*main__categories*/}
          {isMobile && <LinkListGroup data={_data.link_list_group} skip={3} limit={3} />}
          {/*end main__categories */}

          {/*main__product-brand-new*/}
          <Lazyload throttle={500} height={300} scroll={true}>
            <SliderProductNew
              title={_data.product_group_2_title}
              url={_data.product_group_2_url}
              data={_data.product_group_2}
              button_text={_data.product_group_2_button_text}
              button_link={_data.product_group_2_button_link}
            />
          </Lazyload>
          {/*end main__product-brand-new*/}
          <Lazyload throttle={500} height={300} scroll={true}>
            <ShopByCategory title={_data.shop_by_category_title} data={_data.shop_by_category} />
          </Lazyload>
          {/*main__newsletter*/}
          {/* <Newsletter title={_data.a_list_title} text={_data.a_list_text} placeholder={_data.a_list_field_text} image={_data.a_list_image} /> */}
          {/* end main__newsletter*/}
        </>
      )}

      {/*main__beauty-blog*/}
      <Lazyload throttle={500} height={300} scroll={true}>
        <SliderBlogPost />
      </Lazyload>
      {/*end main__beauty-blog*/}

      {/*main__product-new*/}
      <Lazyload throttle={500} height={300} scroll={true}>
        <SliderProductNew
          title={_data.product_group_3_title}
          url={_data.product_group_3_url}
          data={_data.product_group_3}
        />
      </Lazyload>
      {/*end main__product-new*/}

      {/*main__trending_brands*/}

      <React.Fragment>
        {isPC && (
          <Lazyload throttle={500} height={300} scroll={true}>
            <TrandingBrands
              title={_data.trending_brands}
              data={_data.trending_brands_group}
              button_text={_data.trending_brands_button_text}
              button_link={_data.trending_brands_button_link}
            />
          </Lazyload>
        )}
      </React.Fragment>
      {/*end main__trending_brands*/}

      <FooterText className="container-xl" title={_data?.h1_header} text={_data?.footer_text} />
    </Layout>
  );
};

export default memo(IndexPage);

export const query = graphql`
  query HomePage {
    homePage: prismicHomePage {
      data {
        seo {
          meta_title
          meta_description
        }
        promotion_slider_group {
          url {
            url
            uid
            type
            link_type
            document {
              ... on PrismicCategory {
                data {
                  url
                }
              }
              ... on PrismicBrand {
                data {
                  url
                }
              }
            }
          }
          slider_image {
            alt
            gatsbyImageData(width: 303, breakpoints: 303)
          }
          promo_text {
            richText
            text
          }
          promo_heading
          end_date
          start_date
        }
        shop_by_category_title
        shop_by_category {
          image {
            alt
            gatsbyImageData(width: 303, breakpoints: 303)
          }
          title
          url_text
          url_link {
            url
            type
            link_type
            uid
            document {
              ... on PrismicCategory {
                data {
                  url
                }
              }
              ... on PrismicBrand {
                data {
                  url
                }
              }
            }
          }
        }
        link_list_group {
          url {
            url
            uid
            type
            link_type
            document {
              ... on PrismicCategory {
                data {
                  url
                }
              }
              ... on PrismicBrand {
                data {
                  url
                }
              }
            }
          }
          link_name
        }
        banner_group {
          end_date
          start_date
          image {
            gatsbyImageData(layout: FULL_WIDTH)
            alt
          }
          logo {
            gatsbyImageData
            alt
          }
          headline
          subheading
          link_text
          link {
            url
            uid
            type
            link_type
            document {
              ... on PrismicCategory {
                data {
                  url
                }
              }
              ... on PrismicBrand {
                data {
                  url
                }
              }
            }
          }
          theme
          background_color
        }
        product_group_1_title
        product_group_1_url {
          url
          uid
          type
          link_type
        }
        product_group_1 {
          product {
            title
            handle
            admin_graphql_api_id
            images {
              src
            }
            image {
              src
            }
            variants {
              price
              title
              admin_graphql_api_id
            }
            vendor
          }
        }
        product_group_1_button_text
        product_group_1_button_link {
          url
          uid
          type
          link_type
        }
        trending_brands
        trending_brands_group {
          brand_link {
            url
            uid
            type
            link_type
            document {
              ... on PrismicCategory {
                data {
                  url
                }
              }
              ... on PrismicBrand {
                data {
                  url
                }
              }
            }
          }
          brand_logo {
            gatsbyImageData
            alt
          }
        }
        trending_brands_button_text
        trending_brands_button_link {
          url
          uid
          type
          link_type
        }
        product_group_2_title
        product_group_2_url {
          url
          uid
          type
          link_type
        }
        product_group_2 {
          product {
            id
            handle
            admin_graphql_api_id
            image {
              src
            }
            images {
              src
            }
            variants {
              price
              title
              admin_graphql_api_id
            }
            title
            vendor
          }
        }
        product_group_2_button_text
        product_group_2_button_link {
          url
          uid
          type
          link_type
        }
        product_group_3_title
        product_group_3_url {
          url
          uid
          type
          link_type
        }
        product_group_3 {
          product {
            title
            handle
            admin_graphql_api_id
            images {
              src
            }
            image {
              src
            }
            variants {
              price
              title
              admin_graphql_api_id
            }
            vendor
          }
        }
        a_list_title
        a_list_text
        a_list_field_text
        a_list_image {
          gatsbyImageData
          alt
        }
        h1_header {
          richText
        }
        footer_text {
          richText
        }
      }
    }
  }
`;

export const Head = ({ location, params, data, pageContext }) => {
  const { homePage } = data;
  const _data = homePage.data;

  const seo = {
    title: _data.seo[0]['meta_title'],
    description: _data.seo[0]['meta_description'],
    keywords: _data.seo[0]['meta_keywords'],
    hideScript: true,
    location,
  };

  const isPC = loaded && window?.innerWidth >= 768;

  return (
    <Seo {...seo}>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          url: 'https://www.activeskin.com.au/',
          potentialAction: {
            '@type': 'SearchAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: 'https://www.activeskin.com.au?q={search_term_string}',
            },
            'query-input': 'required name=search_term_string',
          },
        })}
      </script>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          url: 'https://www.activeskin.com.au/',
        })}
      </script>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'ItemList',
          itemListElement: _data.banner_group?.map((v, i) => ({
            '@type': 'ListItem',
            position: i + 1,
            url: v?.banner_link?.url,
          })),
        })}
      </script>
      {isPC &&
        _data?.banner_group?.map((v, i) =>
          i < 2 ? <link rel="preload" as="image" href={getSrc(v.banner_image)} /> : null
        )}
    </Seo>
  );
};
